<template>
  <el-form-item
    :key="item.column"
    :label="item.display"
    :prop="item.column">
    <el-row type="flex">
      <component
        :style="{
          width: comWidth
        }"
        :is="fieldComs[item.componentType]"
        :item="item"
        :value.sync="$_value">
      </component>
      <slot></slot>
    </el-row>
  </el-form-item>
</template>

<script>
import fieldComs from './utils/field.com'
export default {
  inject: ['customForm'],
  data () {
    return {
    }
  },
  props: {
    item: {
      type: Object
    },
    value: {},
    // 展示宽度
    comWidth: {
      type: String,
      default: '380px'
    }
  },
  computed: {
    // 根据组件类型取到组件
    fieldComs () {
      return fieldComs
    },
    // 值
    $_value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
      }
    },
    // mode
    mode () {
      return this.customForm.mode
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
