<!-- 选择器 -->
<template>
  <div>
    <el-select
      v-if="mode !== 'static'"
      style="width:100%;"
      placeholder="请选择"
      size="small"
      v-model="$_value"
      filterable
      clearable>
      <el-option
        v-for="optionItem in item.options"
        :key="optionItem"
        :label="optionItem"
        :value="optionItem">
      </el-option>
    </el-select>
    <span v-else>{{ $_value }}</span>
  </div>
</template>

<script>
import itemMixin from '../utils/form.item.mixin'
export default {
  mixins: [itemMixin],
  data () {
    return {
    }
  },
  computed: {
    // 值
    $_value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
