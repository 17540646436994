export default {
  inject: ['customForm'],
  props: ['value', 'item'],
  computed: {
    // mode
    mode () {
      return this.customForm.mode
    },
    // 配置项
    settings () {
      return this.item.settings
    }
  }
}
