<template>
  <div>
    <!-- 输入框 -->
    <el-input maxlength="50" show-word-limit
      v-if="mode !== 'static'"
      :placeholder="item.placeholder"
      style="width: 100%;"
      type="text"
      size="small"
      v-model="$_value">
    </el-input>
    <span v-else>{{ $_value }}</span>
  </div>
</template>

<script>
import itemMixin from '../utils/form.item.mixin'
export default {
  mixins: [itemMixin],
  computed: {
    // 值
    $_value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
