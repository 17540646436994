// 引入表单组件
import inputText from '../form-item/input-text.vue'
import select from '../form-item/select.vue'
import selectTechnical from '../form-item/select-technical.vue'
import selectMultiple from '../form-item/select-multiple.vue'
import textarea from '../form-item/textarea.vue'
import uploadImage from '../form-item/upload-image.vue'
import uploadFile from '../form-item/upload-file.vue'
import price from '../form-item/price.vue'

const fieldComs = {
  inputText,
  select,
  selectTechnical,
  selectMultiple,
  textarea,
  uploadImage,
  uploadFile,
  price
}

export default fieldComs
