<!-- 多项选择器 -->
<template>
  <el-select
    placeholder="请选择"
    size="small"
    v-model="$_value"
    multiple
    :collapse-tags="settings.collapseTags">
    <el-option
      v-for="optionItem in options"
      :key="optionItem"
      :label="optionItem"
      :value="optionItem">
    </el-option>
  </el-select>
</template>

<script>
import { isOfType } from '@/utils/utils'
export default {
  props: ['value', 'item'],
  data () {
    return {
      // 选项
      options: []
    }
  },
  computed: {
    // 值
    $_value: {
      get () {
        // 是字符串并且存在
        if (this.value && isOfType.string(this.value)) {
          return JSON.parse(this.value)
        }
        return []
      },
      set (val) {
        this.$emit('update:value', JSON.stringify(val))
      }
    },
    // 配置项
    settings () {
      if (this.item.settings) {
        return JSON.parse(this.item.settings)
      }
      return {}
    }
  },
  created () {
    // 如果是技术领域的话 则 需要获取Options
    if (this.settings.propType === 'jsly') {
      this.options = ['电子信息', '先进制造与自动化', '航空航天', '生物与新医药', '新材料', '新能源与节能']
    } else {
      this.options = this.item.options
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
