<!--
  1、后端传
-->
<template>
  <el-form
    ref="formData"
    :model="formData"
    :rules="rules"
    size="small"
    :hide-required-asterisk="mode === 'static'"
    :label-width="labelWidth"
    label-position="left">
    <slot></slot>
  </el-form>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  props: {
    // 展示模式 static：静态、edit：对表单进行编辑、enter：对表单进行输入
    mode: {
      type: String,
      default: 'edit'
    },
    // label 宽度
    labelWidth: {
      type: String,
      default: '180px'
    },
    // 用于生成表单的模型
    formModel: {
      type: Array,
      default: () => ([])
    },
    // 表单数据
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  // 注入表单模式
  provide () {
    return {
      customForm: this
    }
  },
  computed: {
    // 表单验证对象
    rules () {
      // 只有在填写表单时 才会有验证信息
      if (this.mode === 'enter') {
        const rules = {}
        this.formModel.forEach(item => {
          rules[item.column] = item.settings.rules || []
        })
        return rules
      }

      return {}
    }
  },
  methods: {
    // 对表单进行验证并取值
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.formData.validate()
          .then(res => {
            resolve(this.formData)
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
