<template>
  <div class="upload_files">
    <el-upload
      v-if="mode !== 'static'"
      ref="uploadFiles"
      :accept="accept"
      :action="action"
      :headers="headers"
      :on-remove="removeFile"
      :on-success="successUpload"
      :on-error="errorUpload"
      :on-exceed="handleExceed"
      :on-preview="handlePreview"
      :before-upload="beforeUpload"
      :file-list="$_value"
      list-type="text">
      <el-button size="small" :loading="uploadBtnLoading" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">允许上传pdf,xlsx,xls,docx,doc,pptx格式的文件，单个文件小于10M</div>
    </el-upload>
    <ul class="docs_list" v-else>
      <li class="docs_list_item" v-for="item in $_value" :key="item.id">
        <a :href="item.downloadUrl" :download="item.originName">
          <div class="docs_list_item_name" :title="item.originName">
            <i class="el-icon-document"></i>{{ item.originName }}
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import cookie from '@/configs/cookie'
import * as api from '@/api'
import itemMixin from '../utils/form.item.mixin'
export default {
  mixins: [itemMixin],
  data () {
    return {
      // 图片上传按钮loading
      uploadBtnLoading: false
    }
  },
  computed: {
    // 值
    $_value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
      }
    },
    // 上传地址
    action () {
      return api.uploadURL
    },
    // 上传携带凭证
    headers () {
      const token = cookie.get(this.globalVar.WEB_TOKEN)
      return {
        Authorization: 'Bearer ' + token
      }
    },
    // 允许上传的类型
    accept () {
      return (
        //  pdf
        'application/pdf,' +
        //  xls
        'application/vnd.ms-excel,' +
        //  xlsx
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
        //  doc
        'application/msword,' +
        //  docx
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
        //  pptx
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      )
    },
    // 允许上传的最大大小
    maxSize () {
      return 10
    }
  },
  methods: {
    // 上传成功
    successUpload (res, file, fileList) {
      this.uploadBtnLoading = false
      /**
       * uid给el做key
       * name: 给el做名字
       */
      this.$_value.push({
        uid: file.uid,
        name: file.name,
        ...res.data
      })
    },
    // 文件上传之前
    beforeUpload (file) {
      this.uploadBtnLoading = true
      if (this.accept && this.$refs.uploadFiles.accept.search(file.type) === -1) {
        this.uploadBtnLoading = false
        this.$message.warning('上传失败，文件格式不正确！')
        return false
      }
      if (file.size / 1024 / 1024 > this.maxSize) {
        this.uploadBtnLoading = false
        this.$message.warning(`上传失败，文件大于${this.maxSize}M`)
        return false
      }
    },
    // 上传文件出错
    errorUpload (error, file, fileList) {
      this.uploadBtnLoading = false
      console.log(error)
      this.$message.error('文件上传出错!')
    },
    // 删除文件
    removeFile (file, fileList) {
      const index = this.$_value.findIndex(item => {
        return item.uid === file.uid
      })
      this.$_value.splice(index, 1)
    },
    // 文件超出个数限制
    handleExceed (files, fileList) {
      this.uploadBtnLoading = false
      this.$message.warning('上传失败，文件数量过多！')
    },
    // 点击文件列表中的文件
    handlePreview (file) {
      const a = document.createElement('a')
      a.download = file.origin_name
      a.href = file.url
      a.click()
    }
  }
}
</script>

<style lang="scss" scoped>
  .upload_files {
    .docs_list {
      .docs_list_item {
        transition: all .5s cubic-bezier(.55,0,.1,1);
        font-size: 14px;
        color: #606266;
        line-height: 32px;
        position: relative;
        border-radius: 4px;
        width: 100%;
        cursor: pointer;
        &:hover {
          background-color: #f5f7fa;
        }
        .docs_list_item_name {
          color: #606266;
          display: block;
          margin-right: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: color .3s;
          padding-left: 5px;
          white-space: nowrap;
          & > i {
            height: 100%;
            margin-right: 7px;
            color: #909399;
            line-height: inherit;
          }
        }
      }
      .docs_list_item+.docs_list_item {
        margin-top: 5px;
      }
    }
  }
</style>
