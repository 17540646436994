<!-- 选择器 - 技术领域 -->
<template>
  <div>
    <el-select
      v-if="mode !== 'static'"
      style="width:100%;"
      placeholder="请选择技术领域"
      size="small"
      v-model="$_value"
      filterable
      clearable>
      <el-option
        v-for="optionItem in options"
        :key="optionItem.id"
        :label="optionItem.name"
        :value="optionItem.id">
      </el-option>
    </el-select>
    <span v-else>{{ $_value | formatValue(options) }}</span>
  </div>
</template>

<script>
import itemMixin from '../utils/form.item.mixin'
import * as api from '@/api'
export default {
  mixins: [itemMixin],
  data () {
    return {
      options: []
    }
  },
  computed: {
    // 值
    $_value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
      }
    }
  },
  created () {
    this.getTechnicalList()
  },
  methods: {
    // 获取技术领域下拉框列表
    getTechnicalList () {
      api.getTechnicalList().then(res => {
        if (res.data.code === 0) {
          this.options = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    }
  },
  filters: {
    formatValue (value, options) {
      if (value) {
        const item = options.find(item => {
          return item.id === value
        })
        return item ? item.name : ''
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
