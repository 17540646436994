<!-- 多行文本输入框 -->
<template>
  <div>
    <el-input
      v-if="mode !== 'static'"
      style="width: 100%;"
      type="textarea" maxlength="300" show-word-limit
      :rows="3"
      :placeholder="item.placeholder"
      size="small"
      v-model="$_value">
    </el-input>
    <span v-else>{{ $_value }}</span>
  </div>
</template>

<script>
import itemMixin from '../utils/form.item.mixin'
export default {
  mixins: [itemMixin],
  computed: {
    // 值
    $_value: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
